import { AppLocale, isLocale, localeDefault } from '@/i18n/config';
import { Entry } from '@/lib/parsers/entries';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { LocalisedFragment } from '__generated__/graphql';

type LocalisedFragmentItem = NonNullable<NonNullable<LocalisedFragment['localized']>[0]>;

export type EntryLocale = {
  siteHandle: string;
  locale: AppLocale;
  uri: string;
  current?: boolean;
};

const stripSlashes = (str: string) => str.replace(/^\/|\/$/g, '');

const isDefaultLocale = (locale: string) => locale === localeDefault;

const parseLocale = (locale: string): AppLocale =>
  locale === 'default' ? localeDefault : isLocale(locale) ? locale : localeDefault;

export default function parseEntryLocales(data: MaybeArrayOf<LocalisedFragmentItem>, entry: Entry) {
  const currentLocale = parseLocale(entry.siteHandle);
  let currentUri = `/${stripSlashes(entry.uri)}`;

  if (isDefaultLocale(currentLocale)) {
    currentUri = `/${localeDefault}${currentUri}`;
  }

  const currentLocaleObj = {
    siteHandle: entry.siteHandle,
    locale: currentLocale,
    uri: currentUri,
    current: true,
  };

  const items = makeNonNullableArray(data);

  const locales = items.reduce(
    (acc, item) => {
      const locale = parseLocale(item.siteHandle as string);

      const thisLocale: EntryLocale = {
        siteHandle: locale,
        locale,
        uri: `/${stripSlashes(item.uri || '')}`,
      };

      return [...acc, thisLocale];
    },
    [currentLocaleObj] as EntryLocale[]
  );

  return {
    locales,
    currentLocale,
  } as {
    locales: EntryLocale[];
    currentLocale: AppLocale;
  };
}
