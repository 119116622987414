'use client';

import { AppLocale } from '@/i18n/config';
import { useTranslations } from 'next-intl';
import Icon from '../Icon';
import Btn, { BtnProps } from '../ui/Btn';
import Txt from '../ui/Txt';
import { languageOptions } from './languageOptions';
import * as style from './LanguageSelector.css';

type LanguageSelectorButtonProps = OverrideProps<
  BtnProps<'button'>,
  {
    locale: AppLocale;
    variant?: 'default' | 'minimal';
  }
>;

const LanguageSelectorButton = ({ locale, variant, ...props }: LanguageSelectorButtonProps) => {
  const t = useTranslations('language');
  const CurrentIcon = languageOptions[locale].Icon;
  const currentLabel = languageOptions[locale].name;
  return (
    <Btn {...props} className={style.button({ variant })} variant="unstyled">
      <Txt className={style.buttonLabel} bold>
        {t('selectLanguage')}
      </Txt>

      <span className={style.buttonIconWrap}>
        <CurrentIcon className={style.buttonIcon} />
        <Txt className={style.buttonIconLabel}>{currentLabel}</Txt>
      </span>
      <Icon name="chevronDown" className={style.buttonCaret} />
    </Btn>
  );
};

export default LanguageSelectorButton;
