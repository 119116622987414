import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useTranslations as getTranslations } from 'next-intl';

dayjs.extend(isBetween);

export const createEventDate = (
  eventStartDate: string | undefined,
  eventEndDate: string | undefined
): { eventDate: string | null; isPast: boolean | null } => {
  const t = getTranslations('article');

  if (!eventStartDate && !eventEndDate) {
    return { eventDate: null, isPast: null };
  }

  const startDate = eventStartDate ? dayjs(eventStartDate, 'D MMMM YYYY') : undefined;
  const endDate = eventEndDate ? dayjs(eventEndDate, 'D MMMM YYYY') : undefined;
  const currentDate = dayjs();

  const startIsFuture = startDate ? currentDate.isBefore(startDate, 'day') : false;
  const endIsFuture = endDate ? currentDate.isBefore(endDate, 'day') : false;

  const isOngoing =
    startDate && endDate ? currentDate.isBetween(startDate, endDate, 'day', '[]') : false;

  // Check if startDate is after before endDate
  if (startDate && endDate && startDate.isAfter(endDate)) {
    return {
      eventDate: null,
      isPast: null,
    };
  }

  // Check if endDate is before startDate
  if (startDate && endDate && endDate.isBefore(startDate)) {
    return {
      eventDate: null,
      isPast: null,
    };
  }

  if (isOngoing) {
    return {
      eventDate: t('eventRunning', {
        startDate: startDate?.format('D MMMM YYYY'),
        endDate: endDate?.format('D MMMM YYYY'),
      }),
      isPast: false,
    };
  }

  if (startDate && !endDate) {
    const startTime = startDate.format('HH:mm A');

    // Check if startTime is 00:00 AM or 00:00 PM
    const isMidnight = startTime === '00:00 AM' || startTime === '00:00 PM';

    return {
      eventDate: startIsFuture
        ? t('eventToBeHeld', {
            date: startDate.format('D MMMM YYYY'),
            time: isMidnight ? '' : `at ${startTime}`,
          })
        : t('watchOnDemand'),
      isPast: !startIsFuture,
    };
  }

  if (endDate && !startDate) {
    const endTime = endDate.format('HH:mm A');

    const isMidnight = endTime === '00:00 AM' || endTime === '00:00 PM';

    return {
      eventDate: endIsFuture
        ? t('eventToBeHeld', {
            date: endDate.format('D MMMM YYYY'),
            time: isMidnight ? '' : `at ${endTime}`,
          })
        : t('watchOnDemand'),
      isPast: !endIsFuture,
    };
  }

  if (startDate && endDate) {
    const bothDatesInFuture = startIsFuture && endIsFuture;

    // Get startDate time
    const startTime = startDate.format('HH:mm A');
    const endTime = endDate.format('HH:mm A');

    const startIsMidnight = startTime === '00:00 AM' || startTime === '00:00 PM';
    const endIsMidnight = endTime === '00:00 AM' || endTime === '00:00 PM';

    const bothAreMidnight = startIsMidnight && endIsMidnight;
    const startIsMidnightAndEndIsnt = startIsMidnight && !endIsMidnight;
    const endIsMidnightAndStartIsnt = !startIsMidnight && endIsMidnight;

    const time = bothAreMidnight
      ? ''
      : startIsMidnightAndEndIsnt
      ? `at ${endTime}`
      : endIsMidnightAndStartIsnt
      ? `at ${startTime}`
      : `from ${startTime} - ${endTime}`;

    // If the dates are the same and in the future, return the event date
    if (startDate.isSame(endDate, 'day') && bothDatesInFuture) {
      return {
        eventDate: t('eventToBeHeld', {
          date: startDate.format('D MMMM YYYY'),
          time,
        }),
        isPast: false,
      };
    }

    if (bothDatesInFuture || isOngoing) {
      return {
        eventDate: t('eventRunning', {
          startDate: startDate.format('D MMMM YYYY'),
          endDate: endDate.format('D MMMM YYYY'),
        }),
        isPast: false,
      };
    }

    return {
      eventDate: t('watchOnDemand'),
      isPast: true,
    };
  }

  return { eventDate: null, isPast: null };
};
